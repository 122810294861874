import { useAuth0 } from '@auth0/auth0-react';
import { CssBaseline } from '@mui/material';
import * as Sentry from '@sentry/react';
import { tx } from '@transifex/native';
import { wrapUseRoutes } from '@sentry/react';
import { useRoutes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import router from 'src/router/router';
import defineAbilityFor from './config/ability';
import { sentryConfig } from './config/sentry';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { useEffect } from 'react';
import { AbilityContext } from './contexts/canContext';
import DialogProvider from './contexts/dialogContext';
import { useAppSelector } from './hooks/hooks';
import Status500 from './pages/Status/Status500/Status500';
import { selectTheme } from './store/theme/themeSlice';
import ThemeProvider from './theme/ThemeProvider';
import { env } from './env';

Sentry.init(sentryConfig);
const useSentryRoutes = wrapUseRoutes(useRoutes);

function App() {
    const auth = useAuth0();
    const content = useSentryRoutes(router);
    const theme = useAppSelector(selectTheme);
    const ability = defineAbilityFor(auth?.user?.userRoles?.length > 0 ? 'admin' : 'user');


    //TODO: MAYBE WE SHOULD MOVE THIS ELSEWHERE
    const checkSession = async () => {
        if (!auth.isAuthenticated) {
            try {
                await auth.getAccessTokenSilently()
            } catch (error) {
                //not authorized
            }
        }
    };

    const MINUTE_MS = 900000;

    useEffect(() => {
        checkSession()
        const interval = setInterval(() => {
            checkSession()
        }, MINUTE_MS);

        return () => clearInterval(interval);
    }, [])

    // Transifex initialization
    // Set Transifex token
    tx.init({
        token: env.REACT_APP_TRANSIFEX_TOKEN,
    });

    return (
        <>

            {<ThemeProvider>
                <CssBaseline />
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <Sentry.ErrorBoundary
                        fallback={<Status500 resetErrorBoundary={() => window.location.reload()} />}
                        onError={(error: Error) => console.error('Err FallBack:', error)}
                    >
                        <AbilityContext.Provider value={ability}>
                            <DialogProvider>
                                <ToastContainer
                                    theme={theme === 'dark' ? 'dark' : 'light'}
                                />
                                {content}
                            </DialogProvider>
                        </AbilityContext.Provider>
                    </Sentry.ErrorBoundary>
                </LocalizationProvider>
            </ThemeProvider>}
        </>
    );
}

export default Sentry.withProfiler(App);
//edit screen
//sync on get